
import { defineComponent } from "vue";

export default defineComponent({
  name: "Spinner",
  props: {
    width: {
      type: String,
      default: "40px"
    },
    height: {
      type: String,
      default: "40px"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
});
